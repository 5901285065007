<template>
  <div>
    <b-row
      align-h="between"
      @click="collapse = !collapse"
    >
      <b-col
        cols="auto"
        class="text-uppercase font-weight-bold d-flex align-items-center cursor-pointer"
      >
        Historial de cobros
      </b-col>
      <b-col cols="auto">
        <b-link>
          <FeatherIcon
            v-if="!collapse"
            icon="ChevronDownIcon"
          />
          <FeatherIcon
            v-else
            icon="ChevronUpIcon"
          />
        </b-link>
      </b-col>
    </b-row>
    <hr>
    <b-collapse v-model="collapse">
      <b-row
        v-for="collectItem in collects"
        :key="collectItem.key"
        align-v="center"
      >
        <b-col>
          <span class="font-weight-bold">
            {{ collectItem.amount | numberToLocalString }} €
          </span>
          <span v-show="collectItem.collect_date"> - {{ collectItem.collect_date | formatDate }}</span>
        </b-col>
        <b-col cols="auto">
          <b-link
            class="text-danger"
            @click="handleDeleteIconClick(collectItem)"
          >
            <feather-icon
              v-b-tooltip.hover 
              title="Eliminar"
              icon="TrashIcon"
              size="18"
            />
          </b-link>
        </b-col>
        <b-col cols="12">
          <hr>
        </b-col>
      </b-row>
    </b-collapse>
  </div>
</template>

<script>
import SaleInvoiceCollectsApi from '@/api/sale-invoice-collects-api'
import ApiRestService from '@/api/base-api'
import { mapActions } from 'vuex'

export default {
  name: 'SaleInvoiceCollectList',
  props: {
    saleInvoice: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      collects: [],
      collapse: true,
    }
  },
  computed: {
    filters() {
      return this.saleInvoice ? { sale_invoice_id: this.saleInvoice.id } : null
    },
  },
  watch: {
    saleInvoice: {
      deep: true,
      handler() {
        this.loadCollects()
      },
    },
  },
  methods: {
    ...mapActions('saleInvoice', ['loadSaleInvoice']),
    async loadCollects() {
      if (!this.saleInvoice) {
        return
      }

      this.loading = true
      try {
        const response = await ApiRestService.list(
          { filters: this.filters },
          this.$data.$constants.RESOURCES.RESOURCE_SALE_INVOICE_COLLECTS,
        )
        this.collects = response.data
      } finally {
        this.loading = false
      }
    },
    async handleDeleteIconClick(saleInvoiceCollect) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: 'Se borrará el registro.',
      })

      if (!response.isConfirmed) {
        return
      }

      this.loading = true
      try {
        await SaleInvoiceCollectsApi.delete(saleInvoiceCollect.id)
        await this.loadSaleInvoice(this.saleInvoice.id)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>

<template>
  <b-card no-body>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
    >
      <b-card-title
        class="m-0 py-1 px-2"
      >
        <b-row
          align-h="end"
          align-v="center"
        >
          <b-col cols="auto">
            <b-link
              v-access="{
                resource: $data.$constants.RESOURCES.RESOURCE_SALE_INVOICES,
                resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
              }"
              :to="{ name: 'editSaleInvoice', params: { id: $route.params.id} }"
              class="d-inline-block px-50 text-indigo"
            >
              <feather-icon
                v-b-tooltip.hover 
                title="Editar"
                icon="Edit2Icon"
                size="18"
              />
            </b-link>
            <b-link
              v-access="{
                resource: $data.$constants.RESOURCES.RESOURCE_SALE_INVOICES,
                resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
              }"
            >
              <span
                class="d-inline-block px-50 text-danger"
                @click="handleDeleteSaleInvoiceButtonClick()"
              >
                <feather-icon
                  v-b-tooltip.hover 
                  title="Eliminar"
                  icon="TrashIcon"
                  size="18"
                />
              </span>
            </b-link>
          </b-col>
        </b-row>
        <b-row
          align-v="center"
          align-h="between"
        >
          <b-col cols="auto">
            <small class="text-dark font-weight-bold">Factura Nº {{ saleInvoice ? saleInvoice.code : '' }}</small>
            <div class="font-weight-bold text-capitalize text-dark mb-0">
              {{ saleInvoice && saleInvoice.client ? saleInvoice.client.name : '' }}
              <StatusBadge
                class="font-small-3 ml-2"
                :status="saleInvoice ? saleInvoice.status.name : ''"
              />
              <StatusBadge
                  v-if="saleInvoice && saleInvoice.received_payment_document && saleInvoice.received_payment_document === 1"
                  class="font-small-3 mt-1"
                  :status="'Rec. Doc. Cobro'"
                  :text="'Rec. Doc. Cobro'"
                  :variant="'light-success'"
              />
            </div>
            <small>
              <span class="font-weight-bold">
                {{ saleInvoiceDate }}
              </span> 
              vence {{ saleInvoiceDueDate }}
            </small>
            <small>
              cobro {{ saleInvoiceCollectDate }}
            </small>
          </b-col>
          <b-col cols="auto">
            <b-row
              align-v="end"
              class="flex-column"
            >
              <b-col cols="auto">
                <b-button
                  variant="primary"
                  class="text-nowrap rounded-sm px-2 d-flex align-items-center mt-1"
                  @click="handlePrintLinkClick"
                >
                  <feather-icon
                    :icon="saleInvoiceDocument ? 'RefreshCcwIcon' : 'UploadIcon' "
                    size="19"
                    class="mr-25"
                  />
                  {{ saleInvoiceDocument ? 'Regenerar PDF' : 'Generar PDF' }}
                </b-button>
              </b-col>
              <b-col cols="auto">
                <b-link
                  v-if="saleInvoiceDocument"
                  class="text-decoration-none text-indigo font-small-3"
                  @click="handleSaleInvoiceDocumentDownload"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="19"
                    class="mr-25"
                  />
                  <u>{{ saleInvoiceDocument.name }}</u>
                </b-link>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row v-if="saleInvoice && saleInvoice.mismatched_certification.id" class="mt-1">
          <b-col sm="12" md="12">
            <b-alert show variant="warning" class="d-flex justify-content-between align-items-center p-1 mb-0">
              <div class="d-flex">
                <div>
                  <feather-icon
                    icon="AlertTriangleIcon"
                    size="20"
                    class="mr-1"
                  />
                </div>
                {{ saleInvoice.mismatched_certification.message }}
              </div>
              <b-button
                variant="primary"
                class="rounded-sm"
                @click="fixCertification"
              >
                Ajustar certificación
              </b-button>
            </b-alert>
          </b-col>
        </b-row>
      </b-card-title>
      <hr class="m-0">
      <ProfilePanel :profile="saleInvoiceProfile" />
      <ContactsPanel 
        v-if="(saleInvoice && (!!saleInvoice.contact || saleInvoice.job.contacts.length))"
        :title="'Contacto facturación'"
        :contacts="!!saleInvoice.contact ? 
          [saleInvoice.contact, ...saleInvoice.job.contacts] : 
          [...saleInvoice.job.contacts]
        " 
      />
    </b-overlay>
  </b-card>
</template>

<script>
import ProfilePanel from '@/components/ui/panel/ProfilePanel.vue'
import ContactsPanel from '@/components/ui/panel/ContactsPanel.vue'
import SaleInvoicesApi from '@/api/sale-invoices-api'
import { mapActions, mapGetters } from 'vuex'
import StatusBadge from '@/components/status/badge/StatusBadge.vue'
import ApiRestService from '@/api/base-api'

export default {
  name: 'SaleInvoicesProfileCard',
  components: {
    StatusBadge,
    ProfilePanel,
    ContactsPanel
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters('saleInvoice', {
      saleInvoice: 'getSaleInvoice',
    }),
    saleInvoiceDate() {
      return this.saleInvoice?.invoice_date ? new Date(this.saleInvoice.invoice_date).toLocaleDateString() : ''
    },
    saleInvoiceDueDate() {
      return this.saleInvoice?.invoice_due_date ? new Date(this.saleInvoice.invoice_due_date).toLocaleDateString() : ''
    },
    saleInvoiceCollectDate() {
      return this.saleInvoice?.invoice_collection ? new Date(this.saleInvoice.invoice_collection).toLocaleDateString() : ''
    },
    saleInvoiceRetentionExpiration() {
      return this.saleInvoice?.retention_expiration ? new Date(this.saleInvoice.retention_expiration).toLocaleDateString() : ''
    },
    saleInvoiceDocument() {
      if (!this.saleInvoice || Array.isArray(this.saleInvoice.document)) {
        return null
      }

      return this.saleInvoice.document
    },
    saleInvoiceProfile() {
      return [
        { label: 'División', value: this.saleInvoice?.division?.name || 'Sin división' },
        { label: 'Obra', value: this.saleInvoice?.job?.project?.name || 'Sin obra'},
        { 
          label: 'Job', 
          value: this.saleInvoice?.job?.name || 'Sin Job', 
          link: !!this.saleInvoice?.job, 
          id: this.saleInvoice?.job?.id,
          aditionalInfo: this.saleInvoice?.job_contract?.name,
          routeName: 'viewJob' 
        },
        { label: 'Retención', value: this.retentionText },
      ]
    },
    retentionText() {
      if (!this.saleInvoice || !this.saleInvoice.retention_percent) {
        return 'Sin retención'
      }

      return `${this.saleInvoice.retention_percent} % vence ${this.saleInvoiceRetentionExpiration}`
    },
  },
  methods: {
    ...mapActions('app', ['setLoading']),
    ...mapActions('saleInvoice', ['loadSaleInvoice']),
    async fixCertification () {
      this.loading = true
      try {
        await SaleInvoicesApi.fixCertification(this.saleInvoice?.mismatched_certification?.id)
        await this.loadSaleInvoice(this.saleInvoice.id)
      } finally {
        this.loading = false
      }
    },
    async handlePrintLinkClick() {
      this.loading = true
      try {
        await SaleInvoicesApi.printSaleInvoice(this.saleInvoice?.id)
        await this.loadSaleInvoice(this.saleInvoice.id)
      } finally {
        this.loading = false
      }
    },
    async handleDeleteSaleInvoiceButtonClick() {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `Se borrará el registro Nº${this.saleInvoice.code}.`,
      })

      if (!response.isConfirmed) {
        return
      }

      this.setLoading(true)
      try {
        await SaleInvoicesApi.delete(this.saleInvoice?.id)
        await this.$router.push({ name: 'saleInvoices' })
      } finally {
        this.setLoading(false)
      }
    },
    async handleSaleInvoiceDocumentDownload() {
      if (!this.saleInvoiceDocument) {
        return
      }

      this.loading = true
      try {
        await ApiRestService.downloadArchive(this.saleInvoiceDocument.id, this.saleInvoiceDocument.name)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>

import Vue from 'vue'
import { BaseApi } from '@/api/base-api'
import { RESOURCE_SALE_INVOICE_COLLECTS } from '@/shared/constants/resources'

class SaleInvoiceCollectsApi extends BaseApi {
  constructor(resource) {
    super(resource)
    this.resource = resource
  }

  async getRemaining(saleInvoiceId) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.get(`${this.resource}/remaining/${saleInvoiceId}`)
        resolve(response.data.remaining)
      } catch (error) {
        console.error(error)
        reject(error)
        Vue.$toast.error('Error al obtener el recurso. Por favor, inténtelo de nuevo mas tarde.')
      }
    })
  }
}

export default new SaleInvoiceCollectsApi(RESOURCE_SALE_INVOICE_COLLECTS)

<template>
  <BaseCard
    title="datos de cobro"
    collapsable
    actions-visible
    :footer-visible="false"
  >
    <template #actions>
      <b-link
        class="text-indigo mr-1 font-small-4"
        :disabled="paymentLinkVisible"
        @click.stop="creationModalVisible = true"
      >
        <u>+ Añadir cobro</u>
      </b-link>
    </template>
    <b-row class="p-2">
      <b-col>
        <b-row
          align-v="start"
        >
          <b-col
            cols="12"
            class="pb-1"
          >
            <div class="font-weight-bold">
              Cuenta bancaria:
            </div>
            <div class="text-light">
              {{ saleInvoice && saleInvoice.bank_account ? saleInvoice.bank_account.name : '' }}
            </div>
          </b-col>
          <b-col
            cols="6"
            class="pb-1"
          >
            <div class="font-weight-bold">
              Forma de pago:
            </div>
            <div class="text-light">
              {{ saleInvoice && saleInvoice.type_payment ? saleInvoice.type_payment.name : 'Sin forma de pago' }}
            </div>
          </b-col>
          <b-col
            cols="6"
            class="pb-1"
          >
            <div class="font-weight-bold">
              Fecha de cobro:
            </div>
            <div class="text-light">
              {{ invoiceCollectionDate }}
            </div>
          </b-col>
          <b-col class="pb-1">
            <b-form-checkbox
              :checked="receivedPaymentDocument"
              disabled
              value="1"
              :unchecked-value="null"
            >
              Recepción documento de cobro
            </b-form-checkbox>
          </b-col>
          <b-col class="pb-1">
            <b-form-checkbox
              :checked="collateralRetentionCollected"
              disabled
              value="1"
              :unchecked-value="null"
            >
              Retención por garantía cobrada
            </b-form-checkbox>
          </b-col>
          <b-col class="pb-1">
            <b-form-checkbox
              :checked="withholdingDocumentReceived"
              disabled
              value="1"
              :unchecked-value="null"
            >
              Documento de retención recibido
            </b-form-checkbox>
          </b-col>
          <b-col cols="auto">
            <b-link
              v-if="saleInvoiceCollectionDocument"
              class="text-decoration-none text-indigo font-small-3"
              @click="handleSaleInvoiceDocumentDownload"
            >
              <feather-icon
                icon="DownloadIcon"
                size="19"
                class="mr-25"
              />
              <u>{{ saleInvoiceCollectionDocument.name }}</u>
            </b-link>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <SaleInvoiceCollectList :sale-invoice="saleInvoice" />
      </b-col>
    </b-row>
    <SaleInvoiceCollectCreationModal
      v-model="creationModalVisible"
      :sale-invoice="saleInvoice"
    />
  </BaseCard>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import SaleInvoiceCollectCreationModal from '@/components/sale-invoices/modal/SaleInvoiceCollectCreationModal.vue'
import SaleInvoiceCollectList from '@/components/sale-invoices/list/SaleInvoiceCollectList.vue'
import ApiRestService from '@/api/base-api'

export default {
  name: 'SaleInvoicesBillingCard',
  components: { SaleInvoiceCollectList, SaleInvoiceCollectCreationModal, BaseCard },
  data() {
    return {
      collapse: true,
      creationModalVisible: false,
    }
  },
  computed: {
    ...mapGetters('saleInvoice', {
      saleInvoice: 'getSaleInvoice',
    }),
    collateralRetentionCollected() {
      return this.saleInvoice?.collateral_retention_collected
    },
    receivedPaymentDocument() {
      return this.saleInvoice?.received_payment_document
    },
    withholdingDocumentReceived() {
      return this.saleInvoice?.withholding_document_received
    },
    invoiceCollectionDate() {
      return this.saleInvoice?.invoice_collection ? new Date(this.saleInvoice.invoice_collection).toLocaleDateString() : 'Sin fecha de cobro  '
    },
    paymentLinkVisible() {
      return this.saleInvoice?.status?.name === 'Cobrada'
    },
    saleInvoiceCollectionDocument() {
      if (!this.saleInvoice || Array.isArray(this.saleInvoice.collection_document)) {
        return null
      }

      return this.saleInvoice.collection_document
    },
  },
  methods: {
    async handleSaleInvoiceDocumentDownload() {
      if (!this.saleInvoiceCollectionDocument) {
        return
      }

      this.loading = true
      try {
        await ApiRestService.downloadArchive(this.saleInvoiceCollectionDocument.id, this.saleInvoiceCollectionDocument.name)
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style scoped>
>>> .custom-control-label {
  color: inherit !important;
}
</style>

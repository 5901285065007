<template>
  <b-form-group>
    <b-form-radio-group
      v-model="model"
      :options="options"
      :disabled="disabled"
      @change="$emit('change', $event)"
      @input="$emit('input', $event)"
    />
  </b-form-group>
</template>

<script>
export default {
  name: 'TaxesTypeRadioGroup',
  props: {
    value: {
      type: [Object, String, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [
        { text: 'IVA', value: 'iva' },
        { text: 'IGIC', value: 'igic' },
      ],
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newModel) {
        this.$emit('input', newModel)
      },
    },
  },
}
</script>

<style scoped>
</style>

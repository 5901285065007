<template>
    <div>
      <div class="font-weight-bold px-2 py-1 border-top">
        {{ title || 'Contactos:' }}
      </div>
      <b-row class="px-2 pb-1">
        <b-col   
          v-for="contact in contacts"
          :key="contact.id"
          class="text-light pb-1"
        >
        <p class="m-0">{{ contact.fullname }}</p>
        <p class="m-0">{{ contact.position }}</p>
        <p class="m-0">{{ contact.email }}</p>
        <p class="m-0">{{ contact.phone }}</p>
        <p v-if="contact.billing_contact != null && contact.billing_contact === '1'" class="m-0">
          Contacto de facturación
        </p>
        </b-col>
      </b-row>
    </div>
</template>

<script>
export default {
  name: 'ContactsPanel',
  props: {
    title: {
      type: String,
    },
    contacts: {
     type: Array
    }
  },
}
</script>
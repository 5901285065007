<template>
  <span>
    <TaxesTypeRadioGroup
      v-if="showTypeSelector"
      :value="type"
      class="d-inline-block"
      @change="loadTaxes($event)"
    />
    <BaseSelect
      :id="id"
      :value="value"
      :options="options"
      label="name"
      class="taxes-select"
      :clearable="false"
      :reduce="reduce"
      :class="showTypeSelector ? ' d-inline-block' : ''"
      :disabled="disabled"
      @selected="$emit('option-selected', $event)"
      @input="$emit('input', $event)"
    />
  </span>
</template>

<script>
import ApiRestService from '@/api/base-api'
import TaxesTypeRadioGroup from '@/components/tax/radio-group/TaxesTypeRadioGroup.vue'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'

export default {
  name: 'TaxesSelect',
  components: { BaseSelect, TaxesTypeRadioGroup },
  props: {
    value: {
      type: [Object, String, Number],
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    reduce: {
      type: Function,
      default: item => item,
    },
    showTypeSelector: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
    }
  },
  watch: {
    type: {
      immediate: true,
      handler (type) {
      this.loadTaxes(type)
    },
    }
  },
  methods: {
    async loadTaxes(type) {
      this.options = []
      const response = await ApiRestService.list(
        { filters: { type } },
        this.$data.$constants.RESOURCES.RESOURCE_TAXES,
      )
      this.options = response.data
    }
  },
}
</script>

<style scoped>
.taxes-select.v-select {
  min-width: 11em;
}
</style>

<template>
  <div>
    <SaleInvoicesProfileCard />
    <BaseDocumentationCard
      v-model="documentation"
      :resource="saleInvoice"
      :delete-end-point="deleteEndPoint"
      :update-end-point="updateEndPoint"
      add-link-text="+ Añadir documentación"
      hide-observations
    />
    <SaleInvoicesBillingCard />
    <SaleInvoicesLinesTable />
    <BaseCommentCard :filters="commentsFilters" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SaleInvoicesProfileCard from '@/components/sale-invoices/card/SaleInvoicesProfileCard.vue'
import SaleInvoicesBillingCard from '@/components/sale-invoices/card/SaleInvoicesBillingCard.vue'
import SaleInvoicesLinesTable from '@/components/sale-invoices/table/SaleInvoicesLinesTable.vue'
import BaseDocumentationCard from '@/components/ui/card/BaseDocumentationCard.vue'
import BaseCommentCard from '@/components/ui/card/BaseCommentCard.vue'

export default {
  components: {
    BaseCommentCard,
    BaseDocumentationCard,
    SaleInvoicesLinesTable,
    SaleInvoicesBillingCard,
    SaleInvoicesProfileCard,
  },
  computed: {
    ...mapGetters('saleInvoice', {
      saleInvoice: 'getSaleInvoice',
    }),
    documentation: {
      get() {
        return this.saleInvoice?.documentation || []
      },
      set(value) {
        this.setSaleInvoiceDocumentation(value)
      },
    },
    deleteEndPoint() {
      return this.saleInvoice ? `/sale-invoices/delete_doc/${this.saleInvoice.id}` : null
    },
    updateEndPoint() {
      return this.saleInvoice ? `/sale-invoices/${this.saleInvoice.id}` : null
    },
    commentsFilters() {
      return { sale_invoice_id: this.$route.params.id }
    },
  },
  mounted() {
    this.loadSaleInvoiceDetails()
  },
  methods: {
    ...mapActions('app', ['setLoading']),
    ...mapActions('saleInvoice', ['loadSaleInvoice', 'setSaleInvoiceDocumentation']),
    async loadSaleInvoiceDetails() {
      this.setLoading(true)
      try {
        await this.loadSaleInvoice(this.$route.params.id)
      } catch (error) {
        console.error(error)
        this.$toast.error('Ocurrió un error al obtener la factura. Por favor inténtelo de nuevo mas tarde.')
      }
      this.setLoading(false)
    },
  },
}
</script>

<style scoped>
</style>
